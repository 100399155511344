<template>
	<div class="page">

		<!-- 搜索条件表单 -->
		<el-form class="ser_form" size="small"  label-width="90px">
			<el-form-item class="el_form_item" label="流水编号">
				<el-input class="el_input" v-model="form.ra_num" placeholder="流水编号搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="运单编号">
				<el-input class="el_input" v-model="form.truck_tord_num" placeholder="运单编号搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="上游编号">
				<el-input class="el_input" v-model="form.out_trade_num " placeholder="上游编号搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="账单编号">
				<el-input class="el_input" v-model="form.group_num " placeholder="账单编号搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="货主手机号">
				<el-input class="el_input" v-model="form.shipper_tel" placeholder="货主手机号搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="车主手机号">
				<el-input class="el_input" v-model="form.truck_owner_tel" placeholder="车主手机号搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="司机手机号">
				<el-input class="el_input" v-model="form.driver_tel" placeholder="司机手机号搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="银行卡号">
				<el-input class="el_input" v-model="form.card_num" placeholder="司机手机号搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="支付类型">
				<el-select class="el_input" v-model="form.this_pay_type" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="现金" value="1"></el-option>
					<el-option label="燃油费" value="2"></el-option>
					<el-option label="过路费" value="3"></el-option>
					<el-option label="预付款" value="11"></el-option>
					<el-option label="到付款" value="12"></el-option>
					<el-option label="回单付" value="13"></el-option>
					<el-option label="账单支付" value="14"></el-option>
				</el-select>
			</el-form-item>
			<!-- <el-form-item class="el_form_item" label="开票状态">
				<el-select class="el_input" v-model="form.invoice_status" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="未申请" value="1"></el-option>
					<el-option label="申请中" value="2"></el-option>
					<el-option label="已开出" value="3"></el-option>
					<el-option label="已驳回" value="4"></el-option>
				</el-select>
			</el-form-item> -->
			<el-form-item class="el_form_item" label="结算状态">
				<el-select class="el_input" v-model="form.settle_status" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="未申请" value="1"></el-option>
					<el-option label="待打款" value="2"></el-option>
					<el-option label="已打款" value="3"></el-option>
					<el-option label="结算失败" value="4"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="支付状态">
				<el-select class="el_input" v-model="form.pay_status" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="待审核" value="1"></el-option>
					<el-option label="支付完成" value="2"></el-option>
					<el-option label="失败/打回" value="3"></el-option>
				</el-select>
			</el-form-item>
			<!-- <el-form-item class="el_form_item" label="排序类型">
				<el-select class="el_input" v-model="form.group_num_type" clearable>
					<el-option label="默认排序" value="1"></el-option>
					<el-option label="账单排序" value="2"></el-option>
				</el-select>
			</el-form-item> -->
			<el-form-item class="el_form_item" label="备注">
				<el-input class="el_input" v-model="form.mark" placeholder="备注搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="收款人性质">
				<el-select class="el_input" v-model="form.is_owner" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="司机" value="1"></el-option>
					<el-option label="代收人" value="2"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="是否生僻字">
				<el-select class="el_input" v-model="form.is_rare" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="是" value="1"></el-option>
					<el-option label="不是" value="2"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item elfrom_item" label="支付时间">
				<div class="block">
				<el-date-picker
					v-model="date_value"
					type="datetimerange"
					:picker-options="pickerOptions"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					align="right"
					value-format="timestamp"
				>
				</el-date-picker>
				</div>
			</el-form-item>
			<el-form-item class="el_form_item elfrom_item">
			</el-form-item>
			<el-form-item class="el_form_item elfrom_item" label="打款时间">
				<div class="block">
				<el-date-picker
					v-model="date_value1"
					type="datetimerange"
					:picker-options="pickerOptions"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					align="right"
					value-format="timestamp"
				>
				</el-date-picker>
				</div>
			</el-form-item>
			<el-form-item class="el_form_item elfrom_item"></el-form-item>
			<el-form-item label-width="0">
				<el-button type="primary" @click="cargo_ser">查询</el-button>
				<el-button type="primary" @click="ser_para_clear">清空条件</el-button>
				<el-button type="success" @click="whole_exportFile">全部导出</el-button>
			</el-form-item>
		</el-form>
    <div class="total_info">
      <span>共:{{list.total}}条</span>
	  <span>总金额:{{much_total}}元</span>
    </div>
		<!-- 表格 -->
		<div class="tab_height">
			<el-table :data="list.data" :border="true" :stripe="true" size="small"  v-loading="list.loading">
				<el-table-column label="支付编号/运单编号" width="210px">
					<template slot-scope="scope">
						<div class="tab_line_item">支付: {{scope.row.ra_num}}</div>
						<div class="tab_line_item">运单: {{scope.row.truck_tord_num}}</div>
						<div class="tab_line_item">上游: {{scope.row.tord_info.out_trade_num}}</div>
						<div class="tab_line_item">账单: {{scope.row.group_num}}</div>
						<div class="tab_line_item">发起: {{scope.row.creat_time_text}}</div>
					</template>
				</el-table-column>
				<el-table-column label="装卸货地信息">
					<template slot-scope="scope">
						<div class="tab_line_item">(省市区) {{scope.row.tord_info.case_prov}} / {{scope.row.tord_info.case_city}} / {{scope.row.tord_info.case_county}}</div>
						<div class="tab_line_item">(详&nbsp;&nbsp;&nbsp;细) {{scope.row.tord_info.case_addr?scope.row.tord_info.case_addr:'无'}}</div>
						<div class="tab_line_item">(省市区) {{scope.row.tord_info.aim_prov}} / {{scope.row.tord_info.aim_city}} / {{scope.row.tord_info.aim_county}}</div>
						<div class="tab_line_item">(详&nbsp;&nbsp;&nbsp;细) {{scope.row.tord_info.aim_addr?scope.row.tord_info.aim_addr:'无'}}</div>
					</template>
				</el-table-column>
				<el-table-column label="货主信息" width="160px">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.shipper_info.name}} / {{scope.row.shipper_info.tel}}</div>
						<div class="tab_line_item">{{scope.row.shipper_info.company_name}}</div>
					</template>
				</el-table-column>
				<el-table-column label="车主/司机" width="230px">
					<template slot-scope="scope">
						<div class="tab_line_item">车主: {{scope.row.truck_owner_info.name}} / {{scope.row.truck_owner_info.tel}}</div>
						<div class="tab_line_item">司机: {{scope.row.driver_info.name}} / {{scope.row.driver_info.tel}}</div>
						<div class="tab_line_item">货车: {{scope.row.tord_info.truck_plate_num}}</div>
						<div class="tab_line_item" v-if="scope.row.driver_info.card_user_name">收款人姓名: {{scope.row.driver_info.card_user_name}}</div>
						<div class="tab_line_item" v-if="scope.row.driver_info.card_num">收款人卡号: {{scope.row.driver_info.card_num}}</div>
						<div class="tab_line_item" v-if="scope.row.driver_info.bank_cname">银行名称: {{scope.row.driver_info.bank_cname}}</div>
						<div class="tab_line_item" v-if="scope.row.driver_info.is_owner">收款人性质: {{scope.row.driver_info.is_owner == '1'?"司机":"代收人" }}</div>
					</template>
				</el-table-column>
				<el-table-column label="支付描述" width="125px">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.this_pay_type_text}} / {{scope.row.this_pay_is_last_text}}</div>
						<div class="tab_line_item">备注: {{scope.row.mark?scope.row.mark:"无"}}</div>
					</template>
				</el-table-column>
				<el-table-column label="服务费" width="140px">
					<template slot-scope="scope">
						<div class="tab_line_item">本次付: {{scope.row.this_pay_freight}}元</div>
						<div class="tab_line_item">费率: {{scope.row.extra_service_charge_rate}} / {{scope.row.extra_service_charge_type_text}}</div>
						<div class="tab_line_item">服务费: {{scope.row.extra_service_charge_total}}元</div>
					</template>
				</el-table-column>
				<el-table-column label="支付状态" width="140px">
					<template slot-scope="scope">
						<div class="tab_line_item">金额: {{scope.row.this_payed}}元</div>
						<div class="tab_line_item">{{scope.row.pay_status_text}}</div>
						<div class="tab_line_item">{{scope.row.payed_time_text}}</div>
					</template>
				</el-table-column>
				<!-- <el-table-column label="发票状态" width="140px">
					<template slot-scope="scope">
						<div class="tab_line_item">金额: {{scope.row.this_invoiced}}元</div>
						<div class="tab_line_item">{{scope.row.invoice_status_text}}</div>
						<div class="tab_line_item">{{scope.row.invoiced_time_text}}</div>
					</template>
				</el-table-column> -->
				<el-table-column label="打款状态" width="170px">
					<template slot-scope="scope">
						 <div>{{scope.row.settle_status_text}}</div>
						 <el-button @click="Refresh(scope.row)" size="mini" type="text">状态刷新</el-button>
					</template>
				</el-table-column>
				<el-table-column label="结算状态" width="170px">
					<template slot-scope="scope">
						<div class="tab_line_item">金额: {{scope.row.this_settled}}元 / {{scope.row.this_pay_type_text}}</div>
						<div class="tab_line_item">
							{{scope.row.settle_status_text}}
							<el-button class="btn_left" @click="settle_open(scope.row,scope.row.settle_status_text)" size="mini" type="text" v-if="scope.row.settle_status != 3">结算</el-button>
							<el-button v-if="scope.row.settle_status==2 || scope.row.settle_status==3 && user_info.id ==12" class="btn_left" @click="settle_refuse(scope.row)" size="mini" type="text" style="color:red">驳回</el-button>
						</div>
						<div class="tab_line_item">{{scope.row.settled_time_text}}</div>
					</template>
				</el-table-column>
				<el-table-column label="操作" width="80px">
					<template slot-scope="scope">
						<div class="tab_line_item">
							<el-button v-if="scope.row.settle_status !=3 && scope.row.settle_status !=4" @click="Fast_bankcard(scope.row)" size="mini" type="text">快速绑卡</el-button>
						</div>
					</template>
				</el-table-column>
			</el-table>
		</div>
      <!-- 回到顶部 -->
      <el-backtop target=".page" :bottom="70" :right="120"></el-backtop>
		<!-- 分页 -->
		<el-pagination
			class="margin-top-xl"
			@size-change="page_size_change"
			@current-change="page_current_change"
			:current-page="page.p"
			:page-sizes="[10, 50, 100, 200]"
			:page-size="page.num"
			:total="list.total*1"
			layout="total, sizes,prev, pager, next, jumper"
		></el-pagination>

		<!-- 现金结算界面 -->
		<cashSettle
			@settle_end="settle_close"
			:tord_payed_info="cash_settle.tord_payed_info"
			:is_show="cash_settle.is_show"
			:ids="settlement.id"
			:settle_status_text = "settle_status_text"
		></cashSettle>

		<!-- 燃油费结算界面 -->
		<fuelCostSettle
			@settle_end="settle_close"
			:tord_payed_info="fuel_cost_settle.tord_payed_info"
			:is_show="fuel_cost_settle.is_show"
		></fuelCostSettle>

		<!-- 过路费结算界面 -->
		<roadTollSettle
			@settle_end="settle_close"
			:tord_payed_info="road_toll_settle.tord_payed_info"
			:is_show="road_toll_settle.is_show"
		></roadTollSettle>

		<!-- 快速绑卡弹窗 -->
		<el-dialog
		    title="快速绑卡"
			:visible.sync="bankcard.is_show"
			width="30%"
			@closed="bankcard_close"
			>
		 <el-form
            status-icon
            label-position="left"
            label-width="110px"
          >
		  <el-form-item label="银行卡号">
              <el-input
                v-model="bankcard.card_num"
                placeholder="请输入银行卡号"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label=" 收款人姓名">
              <el-input
                v-model="bankcard.card_user_name"
                placeholder="请输入收款人姓名"
                clearable
              >
              </el-input>
            </el-form-item>
            <el-form-item label="收款人身份证号">
              <el-input
                v-model="bankcard.card_user_id_card_num"
                placeholder="请输入收款人身份证号"
                clearable
              >
              </el-input>
            </el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="bankcard.is_show = false">取 消</el-button>
				<el-button type="primary" @click="bankcard_submit">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import cashSettle from './tord_settle/cash_settle.vue'
	import fuelCostSettle from './tord_settle/fuel_cost_settle.vue'
	import roadTollSettle from './tord_settle/road_toll_settle.vue'
	import { Loading } from "element-ui";
	import xlsx from "xlsx";
	export default {
		components:{
			cashSettle,
			fuelCostSettle,
			roadTollSettle,
		},
		data() {
			return {
				// 用户信息
				user_info:'', //驳回按钮判断 只有开发者身份可以在已打款显示驳回
				//搜索条件
				form: {
					ra_num:'',//货源编号
					truck_cargo_num:'',//货源编号
					shipper_tel:'',//货主手机号
					truck_owner_tel:'',//车主手机号
					driver_tel:'',//司机手机号
					card_num:"",//银行卡号
					this_pay_type:'1',//本次支付类型(1:现金,2:燃油费,3:过路费)
					pay_status:'2',//支付状态(1:待审核,2:支付完成,3:支付失败/打回)
					//invoice_status:'',//开票状态(1:未申请,2:申请中,3:已开出,4:已驳回)
					settle_status:'',//结算给车主的状态(1:未申请,2:待打款,3:已打款,4:结算失败)
					//group_num_type :'1',//排序类型(1:默认排序,2:账单排序)
					out_trade_num:"",//上游编号
					group_num:"",//账单编号
					mark:'',//备注
					creat_time_start:"", //发起开始时间
                    creat_time_end:'',//发起结束时间
					is_owner:'', //收款人性质
					settled_time_start:"",//打款开始时间
                    settled_time_end:"",//打款结束时间
					is_rare:'', //是否生僻字
				},
				excel_arr:[],
				//快速绑卡
				bankcard:{
					is_show:false,
					id:'',
					card_num:'',//银行卡号
					card_user_name:"",//收款人姓名
					card_user_id_card_num:"",//收款人身份证
				},
				//打款状态
				settle_status_text:'',
				//表格数据
				list: {
					loading: false,//loading特效
					data: [],//列表数据
					total: 0,//列表总记录数
					this_pay_type:'',
				},

				//页码信息
				page: {
					need_num:1,
					num: 10,
					p: 1,
				},
				date_value: "", //发起时间
				date_value1: "", //打款时间时间
				//时间选择器
				pickerOptions: {
					shortcuts: [
					{
						text: "最近一周",
						onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit("pick", [start, end]);
						},
					},
					{
						text: "最近一个月",
						onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit("pick", [start, end]);
						},
					},
					{
						text: "最近三个月",
						onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit("pick", [start, end]);
						},
					},
					],
				},
				//总金额
				much_total:"",
				//现金结算页面
				cash_settle:{
					is_show:0,//是否显示
					tord_payed_info:{},//支付记录
				},

				//燃油费结算页面
				fuel_cost_settle:{
					is_show:0,//是否显示
					tord_payed_info:{},//支付记录
				},

				//过路费结算页面
				road_toll_settle:{
					is_show:0,//是否显示
					tord_payed_info:{},//支付记录
				},
				settlement:{
					id:''
				},
			}
		},
		created() {

			//读取货源
			this.get_page_data()
		},
		methods: {
		  //全部导出
			whole_exportFile() {
			Loading.service(this.options);
			this.page.num = 3000;
			this.$my.net.req({
				take_over_control: 1,
				data: {
					mod:'truck_tord_real_time',
				    ctr:'tord_payed_list_by_admin',
					...this.form,
					...this.page,
					excel:1
				},
				callback: (data) => {
				if (data.code == 0) {
					this.page.p++;
					data.msg.list.forEach(item=>{
						this.excel_arr.push(item)
					})
					this.whole_exportFile();
				} else {
				const _data = [
					// 代表 导出 列标题
					[
						"上游编号/账单编号",
						"支付金额",
						"手续费",
						"支付类型",
						"支付状态",
						"支付时间",
						"开票金额",
						"开票状态",
						"结算金额",
						"结算状态",
						"结算时间",
						"收款人姓名",
						"收款卡号",
						"收款人类型",
					 ],
					];
					this.excel_arr.forEach((item) => {
					_data.push(Object.values(item));
					});
					const ws = xlsx.utils.aoa_to_sheet(_data);
					const wb = xlsx.utils.book_new();
					xlsx.utils.book_append_sheet(wb, ws, "SheetJS");
					xlsx.writeFile(wb, "运单信息.xlsx");
					// 加载完毕
					let loadingInstance = Loading.service(this.options);
					this.$nextTick(() => {
					// 以服务的方式调用的 Loading 需要异步关闭
					loadingInstance.close();
					});
					//清空数组
					this.excel_arr = []
					this.page.p = 1;
					this.page.num = 10;
				}
				},
			});
			},
			//弹窗关闭
			bankcard_close(){
				//清空
				this.bankcard={
					is_show:false,
					id:'',
					card_num:'',//银行卡号
					card_user_name:"",//收款人姓名
					card_user_id_card_num:"",//收款人身份证
				  }
			},
			//快速绑卡提交
			bankcard_submit(){
			// 校验
			if (!this.$my.check.is_bank_card(this.bankcard.card_num)) {
					this.$my.other.msg({
					type: "warning",
					str: "银行卡号格式不正确",
					});
					return;
				}
			if (!this.$my.check.id_card_num(this.bankcard.card_user_id_card_num)) {
					this.$my.other.msg({
					type: "warning",
					str: "身份证号格式不正确",
					});
					return;
				}
			if (!this.$my.check.is_name(this.bankcard.card_user_name)) {
					this.$my.other.msg({
					type: "warning",
					str: "姓名格式不正确",
					});
					return;
				}
				//提交
				this.$my.net.req({
					data: {
						mod: "truck_tord_real_time",
						ctr: "get_user_backcard",
						id: this.bankcard.id,
						card_num:this.bankcard.card_num,
						card_user_name:this.bankcard.card_user_name,
						card_user_id_card_num:this.bankcard.card_user_id_card_num,
					},
					callback: (data) => {
						this.$my.other.msg({
							str:'绑定成功',
							type:'success'
						});
						this.bankcard.is_show = false
						//刷新数据
				        this.get_page_data()
					}
				});
			},
			//快速绑卡
			Fast_bankcard(item){
				this.bankcard.is_show = true
				this.bankcard.id = item.id
			},
		   //状态刷新
			Refresh(item) {
			this.$my.net.req({
				data: {
				mod: "truck_tord_real_time",
				ctr: "out_cash_admin_info",
				id: item.id,
				},
				callback: (data) => {
				this.tord_payed_info.bank_trade_status = data.bank_trade_status
				},
			});
			},
			//打开结算界面
			settle_open(item,settle_status_text){
				  this.settlement.id = item.id
				  this.settle_status_text = settle_status_text
				switch(item.this_pay_type){

					case '1'://现金
						this.cash_settle.tord_payed_info=item;	//置入数据
						this.cash_settle.is_show++;				//打开界面
						break;
					case '11'://现金-预付
						this.cash_settle.tord_payed_info=item;	//置入数据
						this.cash_settle.is_show++;				//打开界面
						break;
					case '12'://现金-到付
						this.cash_settle.tord_payed_info=item;	//置入数据
						this.cash_settle.is_show++;				//打开界面
						break;
					case '13'://现金-尾款
						this.cash_settle.tord_payed_info=item;	//置入数据
						this.cash_settle.is_show++;				//打开界面
						break;
					case '14'://现金-账单支付-其他-剩余全部
						this.cash_settle.tord_payed_info=item;	//置入数据
						this.cash_settle.is_show++;				//打开界面
						break;

					case '2'://燃油费
						this.fuel_cost_settle.tord_payed_info=item;	//置入数据
						this.fuel_cost_settle.is_show++;			//打开界面
						break;

					case '3'://过路费
						this.road_toll_settle.tord_payed_info=item;	//置入数据
						this.road_toll_settle.is_show++;			//打开界面
						break;
				}
			},
			settle_close(this_pay_type){//关闭结算界面

				switch(this_pay_type){

					case '1'://现金
						this.cash_settle.is_show=0;				//关闭界面
						this.cash_settle.tord_payed_info={};	//清空数据
						break;

					case '2'://燃油费
						this.fuel_cost_settle.is_show=0;			//关闭界面
						this.fuel_cost_settle.tord_payed_info={};	//清空数据
						break;

					case '3'://过路费
						this.road_toll_settle.is_show=0;			//关闭界面
						this.road_toll_settle.tord_payed_info={};	//清空数据
						break;
				}

				//刷新
				this.get_page_data()
			},


			//驳回结算
			settle_refuse(item){

				//询问
				this.$my.other.confirm({
					content:"点击确定驳回打款申请",
					confirm:()=>{

						//调用接口
						this.$my.net.req({
							data:{
								mod:'truck_tord_real_time',
								ctr:'tord_settle_refuse_by_admin',
								truck_tord_num:item.truck_tord_num,
								ra_num:item.ra_num,
							},
							callback:(data)=>{

								//提醒
								this.$my.other.msg({
									str:'操作成功',
									type:'success'
								});

								//刷新本页
								this.get_page_data()
							}
						})
					}
				})
			},

			//撤回结算
			settle_revoke(item){

				//询问
				this.$my.other.confirm({
					content:"点击确定撤销打款申请",
					confirm:()=>{

						//调用接口
						this.$my.net.req({
							data:{
								mod:'truck_tord_real_time',
								ctr:'tord_settle_revoke_by_admin',
								truck_tord_num:item.truck_tord_num,
								ra_num:item.ra_num,
							},
							callback:(data)=>{

								//提醒
								this.$my.other.msg({
									str:'操作成功',
									type:'success'
								});

								//刷新本页
								this.get_page_data()
							}
						})
					}
				})
			},

			//清空查询条件
			ser_para_clear(){
				this.form={
					ra_num:'',//货源编号
					truck_cargo_num:'',//货源编号
					shipper_tel:'',//货主手机号
					truck_owner_tel:'',//车主手机号
					driver_tel:'',//司机手机号
					card_num:"",//银行卡号
					this_pay_type:'',//本次支付类型(1:现金,2:燃油费,3:过路费)
					pay_status:'',//支付状态(1:待审核,2:支付完成,3:支付失败/打回)
					//invoice_status:'',//开票状态(1:未申请,2:申请中,3:已开出,4:已驳回)
					settle_status:'',//结算给车主的状态(1:未申请,2:待打款,3:已打款,4:结算失败)
					out_trade_num:"",//上游编号
					group_num:"",//账单编号
					//group_num_type :'1',//排序类型(1:默认排序,2:账单排序)
					mark:'',//备注
					is_owner:'', //收款人性质
					create_time_start:"", //发起开始时间
                    create_time_end:'',//发起结束时间

					settled_time_start:"",//打款开始时间
                    settled_time_end:"",//打款结束时间
					is_rare:'', //是否生僻字
				}
				this.date_value1 = ''
				this.date_value=''
				this.page.p = 1
				this.get_page_data()
			},

			//搜索
			cargo_ser(){
			   if (this.date_value) {
					this.form.creat_time_start = parseInt(this.date_value[0] / 1000);
					this.form.creat_time_end = parseInt(this.date_value[1] / 1000);
				} else {
					this.form.creat_time_start = "";
					this.form.creat_time_end = "";
				}
				//开出时间
				if (this.date_value1) {
					this.form.settled_time_start = parseInt(this.date_value1[0] / 1000);
					this.form.settled_time_end = parseInt(this.date_value1[1] / 1000);
				} else {
					this.form.settled_time_start = "";
					this.form.settled_time_end = "";
				}
				//最终处理
				this.page.p = 1;
				this.get_page_data();
			},

			//页宽变化
			page_size_change(num){

				//置入页码
				this.page.num=num
				//读取
				this.get_page_data()
			},

			//页码变化
			page_current_change(p){

				//置入页码
				this.page.p=p
				//读取
				this.get_page_data()
			},

			//获取货源列表
			get_page_data(){
				//加载中...
				// if(this.list.loading){
				// 	return;
				// }

				//置为加载中
				this.list.loading=true;

				//读取数据
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'tord_payed_list_by_admin',
						...this.form,
						...this.page
					},
					callback:(data)=>{
						if(data.total.much_total){
						this.much_total = data.total.much_total
						}else{
						this.much_total = 0
						}
						//加载完毕
						this.list.loading=false

						//总数
						this.list.total=data.max

						//预处理
						for(let item of data.list){


							//数据空值初始化
							item.truck_info=item.truck_info?item.truck_info:{};
							item.driver_info=item.driver_info?item.driver_info:{};
							item.truck_owner_info=item.truck_owner_info?item.truck_owner_info:{};
							item.shipper_info=item.shipper_info?item.shipper_info:{};


							//成交时间
							item.creat_time_text=this.$my.other.totime(item.creat_time);

							//本次支付类型(1:现金,2:燃油费,3:过路费)
							switch(item.this_pay_type){
								case '1':item.this_pay_type_text="现金";break;
								case '2':item.this_pay_type_text="燃油费";break;
								case '11':item.this_pay_type_text="预付款";break;
								case '12':item.this_pay_type_text="到付款";break;
								case '13':item.this_pay_type_text="尾款";break;
								case '14':item.this_pay_type_text="账单支付";break;
							}

							//是否是最后一笔支付(1:是,2:不是)
							switch(item.this_pay_is_last){
								case '1':item.this_pay_is_last_text="最后一笔";break;
								case '2':item.this_pay_is_last_text="非最后一笔";break;
							}

							//额外服务费计算方式(1:除法模式,2:乘法模式)
							switch(item.extra_service_charge_type){
								case '1':item.extra_service_charge_type_text="除法模式";break;
								case '2':item.extra_service_charge_type_text="乘法模式";break;
							}

							//支付状态(1:待审核,2:支付完成,3:支付失败/打回)
							switch(item.pay_status){
								case '1':item.pay_status_text="待审核";break;
								case '2':item.pay_status_text="支付完成";break;
								case '3':item.pay_status_text="支付失败";break;
							}

							//完成/打回 时间
							if(item.pay_status!=1){
								item.payed_time_text=this.$my.other.totime(item.payed_time);
							}else item.payed_time_text="";

							//开票状态(1:未申请,2:申请中,3:已开出,4:已驳回)
							switch(item.invoice_status){
								case '1':item.invoice_status_text="未申请";break;
								case '2':item.invoice_status_text="申请中";break;
								case '3':item.invoice_status_text="已开出";break;
								case '4':item.invoice_status_text="已驳回";break;
							}

							//开票完成时间
							if(['3','4'].indexOf(item.invoice_status)!=-1){
								item.invoiced_time_text=this.$my.other.totime(item.invoiced_time);
							}else item.invoiced_time_text="";

							//结算给车主的状态(1:未申请,2:待打款,3:已打款,4:结算失败)
							switch(item.settle_status){
								case '1':item.settle_status_text="未申请";break;
								case '2':item.settle_status_text="待打款";break;
								case '3':item.settle_status_text="已打款";break;
								case '4':item.settle_status_text="打款失败";break;
							}
							// 结算类型
							// switch(item.this_pay_type){
							// 	case '1':this.list.this_pay_type_text="未申请";break;
							// 	case '2':item.settle_status_text="待打款";break;
							// 	case '3':item.settle_status_text="已打款";break;
							// 	case '4':item.settle_status_text="打款失败";break;
							// }

							//结算完成时间
							if(['3','4'].indexOf(item.settle_status)!=-1){
								item.settled_time_text=this.$my.other.totime(item.settled_time);
							}else item.settled_time_text="";
						}
						//取缓存
						let user_info = this.$my.cache.get("user_info");
						this.user_info = user_info
						//渲染
						this.list.data=data.list
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.page{
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		 height: calc(100% - 40px);
		overflow: auto;
	}
	.ser_form{
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
	}
	.tab_height{
		//height:calc(100% - 134px);
	}
	.el_form_item{
		width: 275px;
	}
	.el_input{
		width:94%
	}
	.btn_left{
		margin: 0;
	}
	.total_info {
  text-align: right;
  font-size: 12px;
  margin-bottom: 10px;
  margin-right: 1px;
  color: #606266;
  span {
    margin-left: 10px;
  }
}
</style>